<template>
  <span></span>
</template>
<script>
export default {
  name: 'Meta',
  props: ['title'],
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title;
      },
    },
  },
};
</script>
