export default {
  namespaced: true,
  state: {
    course: {
      courseCategoryId: -1,
      courseDescription: '',
      courseHashtag: [],
      courseName: '',
      coursePrevImgUrl: '',
      coursePrevImgUrl1: '',
      selected: false,
      price: 0,
      authorName: 'text',
      chapter: [],
      isB2C: false,
      isB2B: false,
      b2Bcontact: '',
      trailVideoId: 0,
      trailerVideoUrl: ''
    },
  },
  actions: {
    getEditData(context, obj) {
      context.commit('GETEDITDATA', obj);
    },
    setCourseData(context, obj) {
      context.commit('SETCOURSEDATA', obj);
    },
    resetCourseData(context) {
      context.commit('RESETCOURSEDATA');
    },
  },
  mutations: {
    GETEDITDATA(state, payload) {
      state.course = {
        ...payload,
        coursePrevImgUrl: payload.coursePrevImgUrl == 'NotSet' ? '' : payload.coursePrevImgUrl,
        coursePrevImgUrl1: payload.coursePrevImgUrl1 == 'NotSet' ? '' : payload.coursePrevImgUrl1,
      };
    },
    SETCOURSEDATA(state, payload) {
      state.course = { ...state.course, ...payload };
    },
    RESETCOURSEDATA(state) {
      state.course = {
        courseCategoryId: -1,
        courseDescription: '',
        courseHashtag: [],
        courseName: '',
        coursePrevImgUrl: '',
        coursePrevImgUrl1: '',
        selected: false,
        price: 0,
        authorName: 'text',
        chapter: [],
        isB2C: false,
        isB2B: false,
        b2Bcontact: '',
        trailVideoId: 0,
        trailerVideoUrl: ''
      };
    },
  },
  getters: {
    course: (state) => state.course,
  },
};
