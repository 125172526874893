import Vue from 'vue';

Vue.prototype.$bus = new Vue();

/* console.log(chapter.nowChapter);
const vm = this;
vm.isOpen = false;
vm.getCurrentTime().then(res => {
return vm.$refs.player.player.unload();
}).then(res => {
console.log(res.data);
vm.videoId = chapter.videoId;
vm.nowChapter = Number(chapter.nowChapter.split('-')[0]);
vm.nowSection = Number(chapter.nowChapter.split('-')[1]);
vm.vid = chapter.url;
vm.chapterContent = chapter.content;
vm.videoStatus = chapter.status;
vm.NowSectionNum = vm.getNowSectionNum();
// vm.$refs.player.update(vm.vid.split('/')[4].split('?')[0]);
vm.$log.debug('change video');
return vm.$refs.player.player.loadVideo(this.vid);
}).then(res => {
console.log('load video');
}).catch(err => {
vm.$log.debug(err);
}); */
