import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import 'bootstrap';
import './bus';
import jquery from 'jquery';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css'; // for bubble theme
import quillEditor from 'vue-quill-editor';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import {
  ValidationObserver, ValidationProvider, extend, localize, configure,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { required, email } from 'vee-validate/dist/rules';
import zhTW from 'vee-validate/dist/locale/zh_TW.json';
import Swal from 'sweetalert2';
import jwt from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import VueApexCharts from 'vue-apexcharts';
import VueLogger from 'vuejs-logger';
import vSelect from 'vue-select';
import store from './store';
import metaComponent from './components/Meta.vue';
import Meta from 'vue-meta';

const isProduction = process.env.NODE_ENV === 'production';

const options = {
  isEnabled: !isProduction,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: false,
  separator: '|',
  showConsoleColors: false,
};

Vue.use(VueLogger, options);

window.$ = jquery;

Vue.use(VueAxios, axios);
Vue.use(VueAwesomeSwiper);
Vue.use(quillEditor);
Vue.use(VueApexCharts);
Vue.use(VueLogger, options);
Vue.use(Meta);


Vue.component('Loading', Loading);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('apexchart', VueApexCharts);
Vue.component('v-select', vSelect);
Vue.component('Meta', metaComponent);

localize('zh_TW', zhTW);

for (const rule in rules) {
  extend(rule, {
    ...rules[rule], // add the rule
    message: zhTW.messages[rule], // add its message
  });
}
configure({
  classes: {
    valid: 'valid',
    invalid: 'invalid',
  },
});
extend('required', {
  ...required,
  message: '此欄位不可為空',
});
extend('barcode', {
  validate: (value) => {
    const regex = /^\/[a-zA-Z0-9.+-]+$/;
    if (value.length < 8 || value.length > 8) {
      return false;
    }
    return regex.test(value);
  },
  message: '格式不正確',
});
extend('email', {
  ...email,
  message: '郵件地址不正確',
});

extend('username', {
  validate: (value) => {
    const regex = new RegExp('^[a-zA-Z0-9 ]+$');
    if (value.length < 6) {
      return false;
    }
    return regex.test(value);
  },
  message: '請輸入6位以上字符的名稱',
});

extend('password', {
  validate: (value) => {
    const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])$');
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,}$/.test(value);
  },
  message: '請輸入6位數以上的密碼(內含大小寫字母及數字)',
});

extend('password2', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: '密碼不一致',
});

extend('http', {
  validate: (value) => /(http(s?)):\/\//i.test(value),
  message: '請輸入有效的連結',
});

extend('orderer', {
  validate: (value) => {
    if (value.length > 4) {
      return false;
    } else {
      return true;
    }
  },
  message: '請輸入4字以內的中文姓名',
});

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (jwtDecode(JSON.parse(localStorage.getItem('userAuth'))).exp > Math.round(new Date().getTime() / 1000)) {
      next();
    } else if (JSON.parse(localStorage.getItem('userAuth')) == null) {
      next({ path: '/login' });
    } else {
      next({ path: '/login' });
    }
  } else {
    next();
  }
});

Vue.prototype.$API_PATH = 'https://api1.metalearning.com.tw';
Vue.prototype.$API_IMGPATH = 'https://imgupload.metalearning.com.tw';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

window.onload = function () {
  // console.log(window.location.href.split('/')[3]);
  if (window.location.href.split('/')[3] === 'dashboard') {
    if (JSON.parse(localStorage.getItem('userAuth')) == null || jwtDecode(JSON.parse(localStorage.getItem('userAuth'))).exp < Math.round(new Date().getTime() / 1000)) {
      window.location.replace('https://www.metalearning.com.tw/login');
      // window.location.replace('http://localhost:8080/login');
    }
  } else if (window.location.href.split('/')[3] === 'admin') {
    if (JSON.parse(localStorage.getItem('userAuth')) == null || jwtDecode(JSON.parse(localStorage.getItem('userAuth'))).exp < Math.round(new Date().getTime() / 1000)) {
      window.location.replace('https://www.metalearning.com.tw/login');
      // window.location.replace('http://localhost:8080/login');
    } else if (JSON.parse(localStorage.getItem('user')).role == 'Member') {
      window.location.replace('https://www.metalearning.com.tw');
      // window.location.replace('http://localhost:8080');
    }
  }
};