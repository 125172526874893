import Vue from 'vue';
import Vuex from 'vuex';
import courseModule from './Course';
import syllabusModule from './Syllabus';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: false,
  state: {
    user: {
      Avatar: '',
      exp: '',
      name: '',
      nameid: '',
      role: '',
      LoginProvider: '',
      ManualVideoUpload: false
    },
    userAuth: '',
    isLoading: false,
    commented: true,
  },
  actions: {
    updateLoading(context, status) {
      context.commit('LOADING', status);
    },
    getUser(context, obj) {
      context.commit('GETUSER', obj);
      context.commit('SETUSERLOCAL', obj);
    },
    getUserFromLocal(context) {
      context.commit('GETUSERFROMLOCAL');
    },
    removeUser(context) {
      context.commit('REMOVEUSER');
    },
    getUserAuth(context, obj) {
      context.commit('GETUSERAUTH', obj);
      context.commit('SETUSERAUTHLOCAL', obj);
    },
    getUserAuthFromLocal(context) {
      context.commit('GETUSERAUTHFROMLOCAL');
    },
    removeUserAuth(context) {
      context.commit('REMOVEUSERAUTH');
    },
    checkExpAuth(context) {
      if (context.state.user.exp < Math.round(new Date().getTime() / 1000)) {
        context.commit('REMOVEUSERAUTH');
      }
    },
    updateProfile(context, obj) {
      context.commit('UPDATEPROFILE', obj);
      context.commit('UPDATEAVATARLOCAL');
      context.commit('GETUSERFROMLOCAL');
    },
  },
  mutations: {
    LOADING(state, status) {
      state.isLoading = status;
    },
    GETUSERFROMLOCAL(state) {
      state.user = JSON.parse(localStorage.getItem('user')) || [];
      if (state.user.length === 0) {

      }
    },
    GETUSER(state, payload) {
      state.user = payload;
    },
    GETUSERAUTH(state, payload) {
      state.userAuth = payload;
    },
    GETUSERAUTHFROMLOCAL(state) {
      state.userAuth = JSON.parse(localStorage.getItem('userAuth')) || [];
      if (state.userAuth.length === 0) {

      }
    },
    SETUSERLOCAL(state, payload) {
      state.user = localStorage.setItem('user', JSON.stringify(payload));
    },
    SETUSERAUTHLOCAL(state, payload) {
      state.userAuth = localStorage.setItem('userAuth', JSON.stringify(payload));
    },
    REMOVEUSER(state, payload) {
      state.user = localStorage.removeItem('user');
      state.user = {
        Avatar: '',
        exp: '',
        name: '',
        nameid: '',
        role: '',
        LoginProvider: '',
        ManualVideoUpload: false
      };
    },
    REMOVEUSERAUTH(state, payload) {
      state.user = localStorage.removeItem('userAuth');
      state.userAuth = '';
    },
    SETCOMMENTMODE(state, payload) {
      state.commented = payload;
    },
    UPDATEPROFILE(state, payload) {
      state.user.Avatar = payload.avatar;
      // state.user. = payload.avatar;
    },
    UPDATEAVATARLOCAL(state) {
      localStorage.setItem('user', JSON.stringify(state.user));
    },
  },
  getters: {
    userState: (state) => state.user,
    userName: (state) => state.user.name,
    Avatar: (state) => (state.user.Avatar == 'NotSet' ? '/static/images/user.png' : state.user.Avatar),
    userId: (state) => state.user.nameid,
    role: (state) => (Array.isArray(state.user.role) ? state.user.role[0] : state.user.role),
    exp: (state) => state.user.exp,
    userAuth: (state) => state.userAuth,
    isLoading: (state) => state.isLoading,
    LoginProvider: (state) => state.user.LoginProvider,
    ManualVideoUpload: (state) => state.user.ManualVideoUpload,
    commented: (state) => state.commented,
  },
  modules: {
    courseModule,
    syllabusModule,
  },
});
