import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/login',
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/forgetPsd',
    name: 'ForgetPsd',
    component: () => import('../views/ForgetPsd.vue'),
  },
  {
    path: '/resetPsd',
    name: 'ResetPsd',
    component: () => import('../views/ResetPsd.vue'),
  },
  {
    path: '/valid',
    name: 'Valid',
    component: () => import('../views/Valid.vue'),
  },
  {
    path: '/dashboard',
    component: () => import('../views/Dashboard.vue'),
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('../views/Profile.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'notifications',
        name: 'Notification',
        component: () => import('../views/Notification.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'courses',
        name: 'Courses',
        component: () => import('../views/Courses.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'overview/:id',
        name: 'Overview',
        component: () => import('../views/Course_overview.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'edit_course',
        component: () => import('../views/EditCourse.vue'),
        children: [
          {
            path: 'basic',
            name: 'Edit1',
            component: () => import('../views/Edit1.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'syllabus',
            name: 'Edit2',
            component: () => import('../views/Edit2.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'pricing',
            name: 'Edit3',
            component: () => import('../views/Edit3.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'online',
            name: 'Edit4',
            component: () => import('../views/Edit4.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'ebasic/:id',
            component: () => import('../views/Edit1.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'esyllabus/:id',
            component: () => import('../views/Edit2.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'epricing/:id',
            component: () => import('../views/Edit3.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'eonline/:id',
            component: () => import('../views/Edit4.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
    ],
  },
  {
    path: '/admin',
    component: () => import('../views/Admin.vue'),
    children: [
      {
        path: 'home',
        name: 'Admin__Home',
        component: () => import('../views/Admin__Home.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'users',
        name: 'Admin__Users',
        component: () => import('../views/Admin__Users.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'user/:id',
        name: 'Admin__User',
        component: () => import('../views/Admin__User.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'courses',
        name: 'Admin__Courses',
        component: () => import('../views/Admin__Courses.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'edit_course/basic/:id',
        component: () => import('../views/Admin__Edit1.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'course/:id',
        name: 'Admin__Course',
        component: () => import('../views/Admin__Course.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'orders',
        name: 'Admin__Orders',
        component: () => import('../views/Admin__Orders.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'order',
        name: 'Admin__NewOrder',
        component: () => import('../views/Admin__NewOrder.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'order/:oid',
        name: 'Admin__Order',
        component: () => import('../views/Admin__Order.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'categories',
        name: 'Admin__Categories',
        component: () => import('../views/Admin__Categories.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'r/user/:role',
        name: 'Admin__Creator',
        component: () => import('../views/Admin__Creator.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'profits',
        name: 'Admin__Profits',
        component: () => import('../views/Admin__Profits.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'profit',
        name: 'Admin__NewProfit',
        component: () => import('../views/Admin__Profit.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'profit/:id',
        name: 'Admin__Profit',
        component: () => import('../views/Admin__Profit.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'report',
        name: 'Admin__Report',
        component: () => import('../views/Admin__Report.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'review',
        name: 'Admin__Review',
        component: () => import('../views/Admin__Review.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 't/courses',
        name: 'Teacher__Courses',
        component: () => import('../views/Teacher__Courses.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 't/edit_course/basic/:id',
        component: () => import('../views/Teacher__Edit1.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 't/courses/:id',
        name: 'Admin__Students',
        component: () => import('../views/Admin__Students.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 't/student/:cid/:id',
        name: 'Teacher__Student',
        component: () => import('../views/Teacher__Student.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/',
    component: () => import('../views/Layout.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue'),
      },
      {
        path: '/all_courses',
        name: 'AllCourse',
        component: () => import('../views/AllCourse.vue'),
      },
      {
        path: '/all_courses/:category',
        name: 'AllCourse__c',
        component: () => import('../views/AllCourse.vue'),
      },
      {
        path: '/courses_tag',
        name: 'CoursesTag',
        component: () => import('../views/CoursesTag.vue'),
      },
      {
        path: '/course/:id',
        name: 'Course',
        component: () => import('../views/Course.vue'),
      },
      {
        path: '/course_main/:vid',
        name: 'Course_main',
        component: () => import('../views/Course_main.vue'),
      },
      {
        path: '/error',
        name: 'Error',
        component: () => import('../views/Error.vue'),
      },
      // {
      //   path: '/checkout/:id',
      //   name: 'Checkout',
      //   component: () => import('../views/Checkout.vue'),
      // },
      {
        path: '/order/:id',
        name: 'OrderComplete',
        component: () => import('../views/OrderComplete.vue'),
      },
      {
        path: '/checkout/:id',
        name: 'Order',
        component: () => import('../views/Order.vue'),
      },
      {
        path: '/testedit',
        name: 'Testedit',
        component: () => import('../views/testedit.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
