export default {
  namespaced: true,
  state: {
    syllabus: [],
    tempSyllabus: {
      id: '',
      title: '',
      section: [{
        id: '',
        title: '',
        videoUrl: '',
        videoId: '',
        desc: '',
        lock: true,
        time: '',
      }],
    },
    tempSection: {
      id: Math.floor(Date.now()),
      title: '',
      videoUrl: '',
      videoId: '',
      desc: 'content',
      lock: true,
      time: '',
    },
  },
  actions: {
    getSyllData(context, obj) {
      context.commit('GETSYLLDATA', obj);
    },
    setSyllData(context, obj) {
      context.commit('SETSYLLDATA', obj);
    },
    resetSyllData(context) {
      context.commit('RESETSYLLDATA');
    },
  },
  mutations: {
    GETSYLLDATA(state, payload) {
      state.syllabus = payload;
    },
    SETSYLLDATA(state, payload) {
      state.syllabus = [...state.syllabus, ...payload];
    },
    RESETSYLLDATA(state) {
      state.syllabus = [];
    },
  },
  getters: {
    syllabus: (state) => state.syllabus,
  },
};
